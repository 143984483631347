.right-navbar-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end !important;
  padding-right: 5rem;
  button{
      width: fit-content;
      margin: 0 .75rem;
      background-color: transparent;
      border: none;
      padding: 0;
      i {
        color: var(--navFontColor);
        font-size: 1.5rem;
      }
  }
  .sb-avatar.sb-avatar--text {
    padding-top: 0.1rem;
    padding-right: 0.3rem;
    height: 100% !important;
    .sb-avatar__text {
      height: 100% !important;
    }
  }
  .nav-item.dropdown {
    margin-left: 0;
  }

}
#client-navbar {
  .navbar-brand {
    height: 88px;
    margin: 0;
    padding: 0 0.1rem;
  }
}
#basic-nav-dropdown {
  padding: 0;
  text-align: right;
  justify-content: flex-end;
  font-size: 1rem;
  &:hover, &:focus {
    span {
      color: white;
      transition: 0.15s;
    }
  }

}

.social-links {
  display: flex;
  flex-direction: row !important;
  margin: 0;

  padding-right: 0;
  justify-content: flex-end;
  svg {
    color: var(--navFontColor) !important;
  }
  li {
    width: fit-content;
    padding: 0.1rem 0 !important;
  }
}


.li-search {
  width: 1.2rem !important;
}
header .navbar-toggle {
  width: 3.5rem;
}
.li-cart {
  width: 3.2rem;
  button {
    background-color: transparent;
    border: none;
  }
}
.search-active i {
  color: white;
  text-shadow: 2px 2px 4px #000000;
  caret-color: transparent;
}

header > * {
  caret-color: transparent;
}


#client-navbar div.dropdown-menu.show {
  left: -6rem !important;
}
.nav-dropdown-exit {
  height: 1.3rem;
}
.socials-collapsed a {
  margin-right: 0.5rem;
}
#basic-navbar-nav {
  width: 9rem;
}
.dropdown-divider {
  margin: 0 0.2rem;
}
@media screen and (max-width: 991px){
  .right-navbar-container {
    padding-right: 2rem;
    button{
      margin: 0 0.3rem;
  }
    button i,  .sb-avatar__text span {
        font-size: 1.35rem !important;
    }
    .nav-item.dropdown {
      font-size: 1.35rem !important;
      margin-left: -0.3rem;
    }
  }
  .navbar-brand {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .right-navbar-container {
    button {
      margin: 0 0.15rem;
  }
    /* button i,  .sb-avatar__text span {
        font-size: 1.25rem !important;
    } */
    .nav-item.dropdown {
      font-size: 1.25rem !important;
      margin-left: -0.4rem;
    }
  }
  /* #basic-nav-dropdown {
    padding: 10px 6px 0px 0px;
    text-align: right;
    justify-content: flex-end;
    min-width: unset;
    font-size: 1.1rem;
  }
  .right-navbar-container {
    ul {
      padding: 0.2rem 0.45rem;
      padding-right: 0;
      i {
        font-size: 1.1rem;
      }
      li {
        margin-right: 1rem;
      }
    }
    .li-cart {
      margin-right: 0 !important;
      width: 2rem;
    }
  }
 */
}
