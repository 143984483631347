.form-filter-container {
    .btn-primary {
        background-color: transparent !important;
        color: #3980f1 !important;
        &:hover, &:focus, &:active {
            outline: none;
            box-shadow: none !important;
            filter: none !important;
            color: #3980f1 !important;
            background-color: transparent !important;
        }
    }
}
.form-filter {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .actions {
        margin: 0 0.2rem;
        font-size: .8rem;
        padding: .2rem .5rem;
        height: 2rem;
        color: rgba(0, 0, 0, 0.607) !important;
        background-color: #d7d5d591 !important;
        &:hover, &:active, &:focus {
            background-color: #c1bfbf91 !important;
            color: rgba(0, 0, 0, 0.607) !important;
        }
         @media screen and (max-width: 400px) {
            width: 80% !important;
            margin-top: .2rem;
}
    }
        .actions-col {
            display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
            
                @media screen and (max-width: 576px) {
                    margin-top: .2rem;
                    justify-content: flex-start;
                }
            
                @media screen and (max-width: 400px) {
                    justify-content: center;
                }
        }
    .filter-button {
        height: 2rem;
        display: flex;
        justify-content: center;
        padding: .5rem;
        margin: 0;
        svg {
            color: rgba(0, 0, 0, 0.631);
            font-size: 1.2rem;
        }
    }
    .stock-filter {
        svg {
            aspect-ratio: 1/1;
            width: 1.1rem;
            color: black !important;
        }
        .form-control {
            background-color: transparent !important;
            border: none !important;
            font-weight: 500;
        }

    }
    .filter-field {
        height: 100%;
        height: 2.2rem;
        margin: 0.2rem 0.1rem;
    }
    .form-control {
        border: 2px solid rgb(231, 231, 231) !important;
        border-radius: 0.2rem !important;

    }
    button {
        font-size: 0.7rem;
        
    }
    .search-box-container {
        height: 2.4rem;
        border-radius: 2px;
        padding: .2rem;
        & > * {
            height: 100%;
        }
        .input-group-text {
            background-color: white;
            width: 10%;
            &:has(svg) {
            padding: .2rem;
            }
            svg {
                width: 100%;
                height: 100%;
            }
          }
        .input-group {
            border: 2px solid #ECF2F3;;
            .form-control {
                padding-left: 0.25rem;
                font-size: .85rem;
                font-weight: 400;
                border: none !important;
            }
        }
    }

    input {
        font-weight: 600;
        font-size: 0.75rem;
        height: 100%;
        background-color: white !important;

    }
        .dropdown {
            .dropdown-toggle {
                height: 2rem;
                width: 2rem;
                border-radius: 8px;
                padding: .3rem;
                color: rgba(0, 0, 0, 0.725);
                border: 1px solid #F5F5F5;
                background-color: #F5F5F5;
                cursor: pointer;
                transition: 0.2s;
            }
    
            .dropdown-toggle.show {
                transition: 0.2s;
                background-color: #dad8d8;
                color: rgba(0, 0, 0, 0.525);
            }    
            &::before {
                display: none;
            }
        }
        .filter-menu {
            background-color: white;
            border-width: 2px;
            top: 2.2rem;
            font-size: .8rem;
            padding: 0;

                a {
                    transition: 0.3s;
                    font-weight: 400;
                    text-align: center;
                    border-bottom: 1px solid #d7d5d5;
                    padding: 0 0.2rem;
                    font-size: .85rem;
                    &:last-of-type {
                        border-bottom: none;
                    }
                    svg {
                        margin: 0 0.2rem;
                        font-size: 1.4rem;
                        &.min-stock {
                            color: rgb(170, 13, 13);
                        }
                    }
                    &:hover {
                    transition: 0.3s;
                    background-color: #eff2f5a2;
                }
    
                }
    

        }

}
.apply-filters {
    width: auto;

}
.applied-filters {
    .variant-option-pill {
        height: 2rem;
        font-weight: 600 !important;
        font-size: 0.75rem !important;
        margin-bottom: .25rem;
    }
    .filter-field {
        height: 2.2rem;
        margin: 0.2rem 0.1rem;
    }
}
