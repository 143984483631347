#place-order-screen {
      @media screen and (max-width: 576px) {
          & {
            padding-top: 6rem;
            padding-bottom: 6rem;
          }

        }
  .item-options {
    font-size: 0.8rem;
}}

.confirm-order-button {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-spinner {
    vertical-align: middle;
  }
  