#new-product-container .form-control:not(.pill-input) {
    height: calc(2.9rem + 2px);
    border: 1px solid #00000057 !important;
    border-radius: 0.18rem;
}
#new-product-container label {
    opacity: .75;
    font-size: .9rem ;
}
.form-floating>.form-control:focus~label {
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}
#new-product-container .form-floating input:focus,
#new-product-container .form-floating textarea:focus
{
  border: 1px solid var(--secondaryColor) !important;
  box-shadow: none;

}
#new-product-container .form-floating textarea {
    height: 120px !important;
}
#new-product-container .form-floating:has(textarea) {
    padding: 0 calc(var(--bs-gutter-x)* .5);
}

.edit-product-input-wrapper {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    .form-control {
        padding-right: 1rem !important;
      }
  }

  .edit-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .edit-product-fl {
    position: relative !important;
    width: 100% !important;
  }
  .edit-product-fl .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }