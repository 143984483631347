
#new-product-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    gap: 10px;
    padding: 0.5rem !important;
    background-color: rgb(225, 225, 225); 
    background-image: linear-gradient(to bottom, rgb(225, 225, 225)  300px, rgb(248, 248, 248) 300px); 
}
#product-icon {
    height: 70%;
    margin-top: auto ;
    margin-bottom: auto ;
}
#product-icon img{
    background-color: grey;
    height: 70%;
    margin-top: auto ;
    margin-bottom: auto ;
}
#new-product-container h1{
    font-weight: 700;
    font-size: 1.8rem;
}
#new-product-container .card-footer {
    background-color: white;    
    gap: 2px;
}
#new-product-container .card {
    margin-bottom: 1rem;
}
#new-product-container .card-body {
    padding: 2rem 1rem;
}
#new-product-container .card .disabled {
    opacity: 0.5;
}


.chips-product-options {
    border: 1px solid #ced4da;
}
.react-tagsinput--focused {
    border: 1px solid var(--secondaryColor) !important;
}
.chips-product-options {
    border: 1px solid #ced4da;
}
.react-tagsinput-tag {
    background-color: var(--secondaryColor) !important;
    border-color: var(--secondaryColor) !important;
    color: var(--btnFontColor) !important;
}
.card-header h5 {
    margin-bottom: 0.2rem;
}
.new-product-section-caption {
    margin: 0;
    font-size: 0.92rem;
}
.react-tagsinput-remove {
    color: var(--btnFontColor) !important;
}