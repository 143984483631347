.search-box-container {
    border-radius: 0.2rem;
    height: 3.5rem;
    input {
        border-radius: 0.2rem;
        width: 80% ;
        background-color: rgb(251, 251, 251);
        height: 100%;
        border: none !important;
    }
    .input-group {
        height: 100%;
        border-radius: 0.2rem;
        width: 100%;
        padding: 0 !important;
    }
    .input-group-text {
        width: 15%;
        height: 100%;
        justify-content: center;
        border: none  !important;
        background-color: rgb(239, 235, 235);
        i {
            color: #4A5057;
          }
      }
  }
  .input-search-products:focus {
      background-color: rgb(249, 249, 249);
  }