.search-box-overlay {
  display: flex;

  justify-content: center;
  align-items: center;
  height: 3.5rem;
  position: fixed;
  margin-top: 5.6rem;
  top: 0;
  left: 0;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, opacity 0.2s;
  background-color: rgba(0, 0, 0, 0.203);
  caret-color:black;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  .search-box-container {
    width: 70%;
    padding: 0.2rem;
  }
  
}

@media screen and (min-width: 1400px) {
  .search-box-overlay {
    padding: 0 25rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .search-box-overlay {
    padding: 0 18rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .search-box-overlay {
    padding: 0 10rem;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .search-box-overlay {
    padding: 0 5rem;
    margin-top: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .search-box-overlay {
    padding: 0 2rem;
    margin-top: 5rem;
  }
}