/* .select-shipping-address {
    border: 1px solid #0000002e;
    border-radius: 0.15rem;
} */
.select-shipping-address textarea.form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.9rem;
}
.select-shipping-address textarea + label {
  padding: 1rem 1.5rem;
}
.select-shipping-address .row {
  margin-bottom: 0.8rem;
}

.select-shipping-address form {
  padding: 1rem;

  border-radius: 0.2rem;
}

.small-container.select-shipping-address {
  max-width: 700px;
}

.select-shipping-address .form-control,
.select-shipping-address select {
  height: calc(2.9rem + 2px);
  border: 1px solid #00000057 !important;
  border-radius: 0.18rem;
}

/* .select-shipping-address .form-floating label {
    padding-left: 1.2rem;
} */
.select-shipping-address .form-floating input:focus,
.select-shipping-address .form-floating select:focus,
.select-shipping-address .form-floating textarea:focus {
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor) !important;
  box-shadow: none;
}

.select-shipping-address .form-floating input:focus + label,
.select-shipping-address .form-floating select:focus + label,
.select-shipping-address .form-floating textarea:focus + label {
  color: var(--secondaryColor);
}

#shipping-date-input {
  background-color: white;
  border: 1px solid #00000057;
  border-radius: 0.18rem;
}

#shipping-date-input label {
  border: none;
  border-radius: 0%;
  width: 100%;
  background-color: white;
  margin-bottom: 0;
  color: #00000097;
  font-size: 0.85rem;
  padding: 0.3rem 0.75rem 0 0.75rem;
}

#shipping-date-input input {
  border: none !important;
  border-radius: 0%;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
  height: 1.7rem;
}
