.admin-info .logo-photo {
  max-width: 100px;
  max-height: 100px;
}
.admin-info .col-lg-6.col-12 {
  padding-right: 0.5rem;
}
.btn-file {
  font-size: 0.9rem !important;
}
