#cart-screen{
    padding: .5rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media screen and (max-width: 576px) {
        & {
            padding-top: 6rem;
        }
    }
    h5 {
        font-size: 1rem;
    }
    .login-container {
        height: fit-content;
    }
    .cart-row {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e1e1e1;
        height: fit-content;
        align-items: center;
        div {
            text-align: center;
            display: inline;
        }
        img.img-responsive {
            margin: 10px;
            justify-content: center;
            align-items: center;
            height: 10rem;
          }   
    }
    .cart-summary {
        border: none !important;
        background: white;
        max-height: 78vh;
        .order-total::before  {
            content: "$";
        }
        .card-body {
            height:fit-content;
            overflow-y: auto;
        }
        .card-header, .card-footer {
            border: none !important;
        }
        .card-footer {
            button {
                        border: none;
                        height: 2.5rem;
                        font-size: 0.88rem;
                        font-weight: 600;
                        padding: 0 1rem;
                        border-radius: 25px;
                    }
        }
    }

}
@media screen and (min-width: 992px){
    #cart-screen {
        .login-container {
           display: block;
           width: 35%;
        }
        .cart-summary {
            width: 55%;
        }
    }
}
@media screen and (max-width: 992px){
        #cart-screen {
            .cart-summary {
                width: 80%;
                margin: auto;
                a.item-name {
                    font-size: .95rem;
                }
                
                h2 {
                    font-size: 1rem;
                }
                button  {
                    font-size: 0.8rem  !important;
                }
            }
        }
}

@media (max-width: 768px) {
    #cart-screen {
        .cart-summary {
            width: 98%;
        }
    }
  }

  