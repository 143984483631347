.quantity-input-container {
    display: flex;
    width: 100%;

    align-items: center;
}

.product-stock-info {
    font-size: 0.9rem;
    font-weight: 500;
    margin-left: 0.3rem;
    text-align: left;
}

.quantity-input-container label {
    font-size: 0.95rem;
    font-weight: 500;
}

.quantity-input-container {
    margin-top: 1rem;
}

.quantity-input-container input {
    padding: 0 0.2rem;
    margin-left: .4rem;
    font-size: 0.85rem;
    font-weight: 500;
    border: 1px solid #8f8f8f !important;
    background-color: #fafafa;
    border-radius: 4px;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
}