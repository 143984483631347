.carousel-item {
  height: 440px;
}
.carousel-item img {
  height: 100%;
  width: 100%;
}
.negrotest {
  object-fit: cover;
}
