.update-prices-modal {
  thead {
    border-top: none;
  }
  th {
    padding: .2rem;
    font-size: .92rem;
    font-weight: 500;
    border-top: none;
    @media screen and (max-width: 768px) {
      font-size: .85rem;
    }
  }
  tr {
    border: 1px solid #ccc;
    td {
      font-size: .9rem;
      border-right: 1px solid #ccc;
      padding: .2rem;
          @media screen and (max-width: 768px) {
              font-size: .8rem;
            }
    }
  }
  .modal-footer {
    display: flex !important;
    justify-content: space-between;
    button {
      margin: 0;
      /* &.btn-primary {
        background-color: #E9F9F4;
      }
      &.btn-secondary {
        background-color: #FFF2F2;
      } */
    }
  }
}
.toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  height: 2rem;

}

.toggle-button {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: .9rem;
  padding: 10px 15px;
  border: none;
  background: #e6e6e6;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
}

.toggle-button.active {
  background: #ccc;
  color: #000;
}

.toggle-button:not(.active) {
  background: #f9f9f9;
  color: #aaa;
}

.custom-input {
  /* height: 40px !important; */
  padding: 10px;
  font-size: 1rem;
  border-bottom: 1px solid #ccc !important;
  width: 85%;
  text-align: center;
  margin-bottom: .2rem;
  font-size: .88rem;
            @media screen and (max-width: 768px) {
              &, &::placeholder {
                font-size: .9rem;
              }
              }
  &:focus::placeholder {
      color: transparent;
    }
}
.apply-modification {
  height: 2rem !important;
  font-size: .85rem;
  padding: .3rem .8rem !important;
  margin: .5rem 0;
  color: rgba(0, 0, 0, 0.671) !important;
  background-color: #e3e3e3 !important;
  transition: .1s;
  &:hover, &:active, &:focus {
    box-shadow: none !important;
    outline: none !important;
    margin: .5rem 0;
    transition: .1s;
    color: rgba(0, 0, 0, 0.671) !important;
    background-color: #e3e3e3 !important;
  }
}

.switch-container{
  display: flex !important;
  justify-content: space-between !important;
}

.create-discount-code-modal {
  .form-control {
    margin-top: 1rem;
    height: calc(2.9rem + 2px);
    border: 1px solid #00000057 !important;
    border-radius: 0.18rem;
  }

  .form-control.rbt-input-multi {
    margin-top: 0;
  }

  label[for="product-exceptions"] {
    margin-top: 1rem;
  }
  .rbt-token {
    display: flex;
    .rbt-token-label {
      flex: 3;
      font-size: .9rem;
    }
    .rbt-close {
      flex: 1
    }
  }
  .rbt-input-wrapper {
    display: flex;
    height: 100%;
  }
}
#included-products-typeahead {
  background-color: white !important;
}
