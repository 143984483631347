.custom-select {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    display: flex;
    border-radius: 4px;
    border: 1px solid rgb(187, 193, 198);
    cursor: pointer;
    position: relative;
    transition: 0.07s;
    font-size: 1em;
}
.custom-select.disabled, .custom-select.disabled > * {
    cursor: default;
}
.custom-select .content {
    flex: 10;
}
.custom-select .toggle {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 0.3rem;
    align-items: center;
}
.custom-select .toggle button{
    background-color: transparent;
    height: 100%;
    border: none;
}
.custom-select .content,
.custom-select .toggle {
    height: auto;
}
.custom-select label {
    opacity: .65;
    padding: 0 0.3rem;
    font-size: 0.8em;
    text-align: left;
    vertical-align:middle;
    flex: 1;
    width: 100%;
}
.custom-select input {
    border: none;
    width: 100%;
    padding: 0 0.5em 0.5em;
    border-radius: 4px;
    flex: 2;
    background-color: transparent;
    caret-color: transparent;
    cursor: pointer;
}

.custom-select .options{
    width: 100%;
    top: 102%;
    padding: 0.2rem 0;
    z-index: 1;
    max-height: 13rem;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transition: 0.07s;
    overflow-y: scroll;
    background-color: white;
    position: absolute;
    visibility: hidden;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.custom-select .dropdown-option {
    min-height: 1rem;
    padding: .5rem;
}
.custom-select.open .dropdown-option:hover {
    transition: .1s;
    background-color: rgba(0, 0, 0, 0.04);
}

.custom-select.open  {
    border-color: var(--secondaryColor);
    border-bottom-left-radius:0 ;
    border-bottom-right-radius:0;
}
.custom-select.open label {
    color: var(--secondaryColor);
    width: 100%;
}

.custom-select.open .options {
    visibility: visible;
    transition: 0.07s;
}
