.category-details-container.admin-con.container {
  background-color: white;
}
.category-details-container {
  .title {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    svg {
      font-size: 1.5rem;
    }
  }
}

.related-products-container {
  border-top: 1px solid rgba(0, 0, 0, 0.403);
}
