.edit-stock-price-modal {

  h2 {
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
  }

  input:focus {
    border-bottom: 2px solid #000;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button.cancel {
    background-color: #d9534f;
    color: white;
  }

  button.confirm {
    background-color: #5cb85c;
    color: white;
  }
  .modal-content {
    padding: 20px;
    border-radius: 8px;
  }
}
