.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: center;
  }
  .pagination-container .pagination-item {
    transition: 0.08s;
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: black;
    font-weight: 600;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
  }
   .pagination-container .pagination-item.tens {
    padding-left: 0.55rem;
  }
  .pagination-container .pagination-item.twenties {
    padding-left: 0.51rem;
  }
  .pagination-container .pagination-item.thirties-to-sixties,
  .pagination-container .pagination-item.eighties {
    padding-left: 0.48rem;
  }
  .pagination-container .pagination-item.sixties,.pagination-container .pagination-item.nineties  {
    padding-left: 0.47rem;
  }
  .pagination-container .pagination-item.seventies {
    padding-left: 0.5rem;
  }
  .pagination-container .pagination-item.three-figure {
    padding-left: 0.28rem;
  }
  
  .pagination-container .pagination-item.dots:hover {
    cursor: default;
    opacity: 0.6;
  }
  .pagination-container .pagination-item.dots {
    padding-left: 0.65rem;
  }
  .pagination-container .pagination-item:hover {
    background-color: var(--secondaryColor);
    cursor: pointer;
    transition: 0.08s;
  }
  .pagination-container .pagination-item.selected {
    background-color: var(--secondaryColor) !important;
  }
  .pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt;
      Uncomment this to lower the icons as requested in comments*/
    content: "";
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid black;
    border-top: 0.12em solid black;
    opacity: 0.87;
  }
  .pagination-container .pagination-item .arrow.left {
    color: black;
    transform: rotate(-135deg) translate(-50%);
  }
  .pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
    padding-bottom: 0.2rem;
  }
  .pagination-container .pagination-item.disabled {
    pointer-events: none;
  }
  .pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid black;
    border-top: 0.12em solid black;
    opacity: 0.43;
  }
  .pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
  }
  .pagination-bar {
    max-width: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
  }
  .admin-pagination-container {
    min-width: 3rem ;
    margin: 0 auto;
  }