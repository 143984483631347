.email-config .btn-plus {
  margin: 0;
}

.email-config .email {
  padding: 0.2rem 0.3rem;
}

.email-config {
  width: 100%;
  margin: 0;
}

/* estilos global o no */
.email-config .form-control,
.email-config .form-control:focus,
.email-config .form-control:active,
.email-config .form-control:focus-visible {
  border-bottom: 1px solid var(--secondaryColor) !important;
  border-radius: 0%;
}
.email-approval-status {
  display: inline-block;
  padding-left: 0.3rem;
  padding-bottom: 0.1rem;
}
.email-config .badge {
  padding: 0.3em 0.6em !important;
}
.email-config .email-pending {
  background-color: rgba(225, 204, 14, 0.675) !important;
  border-color: rgba(225, 204, 14, 0.675) !important;
}
.email-config .email-check {
  background-color: rgba(9, 188, 69, 0.675) !important;
  border-color: rgba(9, 188, 69, 0.675) !important;
}
.tooltip-email-status {
  width: 2rem;
  font-size: 0.8rem;
}
.email-list {
  font-size: 0.95rem;
  width: 45%;
  border-radius: 0;
}
.email-list .list-group-item {
  border-right: none;
  border-left: none;
  padding: 0;
}
.btn-plus :focus {
  outline: none !important;
}
/* media queries */
@media (min-width: 992px) {
  .email-config {
    max-width: 720px;
  }
}
@media (min-width: 720px) and (max-width: 991px) {
  .email-config {
    max-width: 620px;
  }
  .email-list {
    width: 70%;
  }
}

@media (min-width: 240px) and (max-width: 719px) {
  .email-list {
    width: 90%;
  }
  .email-config {
    max-width: 520px;
  }
}
