.order-shipping-address-screen {
    padding-top: 1rem;
    padding-bottom: 6rem;
    .address-book-footer button {
        display: none;
    }

    @media screen and (max-width: 576px) {
        & {
            padding-top: 6rem;
        }
            .address-book-body {
                        max-width: 17rem;
                }
    }
}