 .address-card {
 height: fit-content;
 width: 20rem  !important;
 margin-bottom: 1rem;
 &#add-card  {
  height: 255px;
 }
}
 .address-card.selected-address {
  border: 1px solid var(--secondaryColor);
}
#selected-badge {
  background-color: var(--secondaryColor) !important ;
}

.address-card-action-buttons {
  display: flex;
  justify-content: center;
  button  {
    border: none;
    padding: 0;
    margin: 0 10px;
    background: transparent;
    font-size: 0.8rem;
    color: black;
    font-weight: 500;
  }
  @media screen and (max-width: 476px) {
    & {
      flex-wrap: wrap;
      button {
        margin-bottom: .5rem;
        width: 100%;
      }
    }
  }
}


.address-card .address-line {
  text-transform: uppercase;
  height: 1.3rem;
  font-size: 0.85rem;
  font-weight: 500;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.address-card .badge {
  font-size: 0.7rem !important;
  padding: 0.35em 0.65em !important;
  font-weight: 500 !important;
  color: black !important;
  background-color: rgb(202, 199, 199) !important;
}
.address-card .card-header {
  height: 2.3rem !important;
  
}
#add-card.address-card {
  border: 2px dashed #C7C7C7;
  cursor: pointer;
}
#add-card.address-card .card-body {
  padding-top: 4.5rem;
}
#add-card.address-card h3 {
  color: rgba(0, 0, 0, 0.723);
  font-weight: 600;
  font-size: 1.3rem;
}

#add-card.address-card .fas.fa-plus {
  display: inline-block;
  margin: auto;
  color: rgba(0, 0, 0, 0.471);
  font-size: 3rem;
}
#add-card.address-card h3 {
  width: fit-content;
}
#plus-container {
  width: fit-content;
  margin: auto;
}
.address-card .card-footer {
  background-color: white;
  border-top: none;
}
.address-card .address-line:first-of-type {
  font-weight: 700;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .address-card {
    width: 15rem  !important;
   }
  
}

