.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps .col {
  text-align: center !important;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: var(--secondaryColor);
}
