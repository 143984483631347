 .mobile-menu {
    flex-direction: row;
    flex-wrap: inherit;
    width: fit-content;
} 

.collapsable-navbar-extended .mobile-menu {
    margin: 2rem 0 0 2.5rem !important;
    background-color: transparent;
    flex-wrap: wrap;
    display: flex;
    width: 20vw;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;
    flex-wrap: inherit;
    width: fit-content;
    padding: 4px 12px;
    font-size: 1.25rem;
}
.collapsable-navbar-extended .mobile-menu li a, #span-configuracion {
    margin-left: 0.2rem;
    font-weight: 700 !important;
    color: rgb(232, 230, 227) !important;
     font-size: calc(1.3rem + .6vw);
  }