
.tabs{
  display: flex;
  justify-content: space-between; /* Distributes space evenly */
  align-items: center; /* Vertically aligns items */
  padding: 0px;
  min-height: max-content;
  margin-bottom: 0;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid;
  border-bottom-color:  #dee2e6 !important;
}

.tabs-container {
  display: flex;
  height: 100%;
  min-height: 45px;
}

.tab {
  display: flex;
  justify-content: space-between; /* Distribute space between tabs and button */
  align-items: flex-end;
  margin:  0px 3px;
  padding: 5px 8px;
  font-weight: 300;
  cursor: pointer;
  color: #4f4f4f;
  font-size: 12px;
  border-radius: 0rem;
  border-bottom:2px solid #545556;
}


.tab.selected {
  border-bottom: 3px solid #545556;
  color: #545556;
  font-weight: bold;
 /* // background-color: #a3a3a3; */

}

.tab:hover{
  background-color: #a3a3a3;

}


.tab .badge {
  background-color: #f0ad4e;
  align-self: flex-start;
  margin-top: 0.2rem;
  color: white;
  padding: 3px 3px;
  border-radius: 10px;
  font-size: 10px;
  position: relative;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}