.dropdown-header {
  font: 1rem sans-serif;
  color: #00377a;
  font-weight: bold;
  text-align: center;
}
.col-md-3 {
  border-radius: 3%;
  background-color: rgb(0, 84, 186, 0.4);
}

a {
  color: #00377a;
  text-decoration: none;
}

.search-screen li {
  width: 100%;
}
.screenRow {
  margin: 1rem auto;
}
ul {
  padding: 1rem;
  margin: 0 auto;
}
.searchCol li a {
  color: #666;
}
.searchCol li span {
  color: #666;
}

.searchDiv {
  color: #666;
}

.tag {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 6px;
  border: 0;
  border-radius: 100px;
  padding: 7px 14px;
  padding-right: 33px;
  float: left;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
}

.tag:not([class*="bg-"]) {
  background-color: #03a9f4;
  color: #fff;
}

.tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 14px;
  line-height: 1;
  margin-top: -5.5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.tag [data-role="remove"]:after {
  font-family: "Font Awesome 5 Free";
  content: "\f00d";
  font-weight: 900;
  font-size: 11px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
