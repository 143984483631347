.image-crop-modal {
    position: relative;
    top: 25%;
    .modal-cropper-container {
        width: 40vw;
    }.modal-content {
        width: fit-content;
    }
    .modal-body {
        width: fit-content;
    }   
}