.address-list {
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.353);
}

.addresses-screen{ 
  width: 65rem;
}
.addresses-screen .address-book-body {
  width: auto;
}
@media screen and (max-width: 576px) {
  .addresses-screen{ 
    width: 90vw !important;
    padding-left: 0;
  }
  .addresses-screen .address-book-body {
    padding-left: 0.2rem;
  }
}

.addresses-screen h2 {
  font-weight: 700;
  margin-bottom: 1rem;
}
.addresses-screen .message-box {
  width: 100%;
  padding: 0.5rem;
}
