#mobile-menu-admin {
  flex-wrap: wrap;
  display: flex;
  margin: 0;
  gap: 0.2rem; 
  color: rgba(4, 30, 73,0.5) !important;
  justify-content: flex-start;
  cursor: pointer;
  flex-direction: column;
  align-content: center;
}
#mobile-menu-admin a {
  font-size: 0.85em;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: rgba(4,30,73,0.7) !important;
  transition: 0.1s;
  padding: 0.2rem;
  border-radius: 8px;
  margin: 0 0.2rem;
}
#mobile-menu-admin .nav-option a:hover {
  transition: 0.1s;
  background-color: #e1ebfa;
}
#mobile-menu-admin .nav-option a:focus, #mobile-menu-admin .nav-option a.focused-option {
  background-color: #a7c7fa;
  color: rgba(4,30,73) !important
}
.collapsable-navbar-extended #mobile-menu-admin .nav-option a:focus, .focused-option {
  background-color: transparent;
}
.collapsable-navbar-extended #mobile-menu-admin .admin-company-logo {
  visibility: hidden ;
}
#mobile-menu-admin .admin-company-logo a{
  padding: 0.5rem !important;
}
#mobile-menu-admin a svg {
  flex: 1;
  font-size: 0.88rem !important;
  padding: 0 0.2rem;
  margin: 0;
}
#mobile-menu-admin a span {
  flex: 4;
}
.admin-company-logo {
  border-bottom: 1px solid rgba(4,30,73,0.1);
  height: 50px;
}

.collapsable-navbar-backdrop + .fade.modal.show {
  background-color: var(--primaryColor);
}
.collapsable-navbar-extended {
  width: 100vw !important;
  margin: 0 !important;
  max-width: 100vw;
}
.collapsable-navbar-extended #mobile-menu-admin {
  margin:  2rem 0 0 2.5rem;
}

.collapsable-navbar-extended .modal-content {
  border: none !important;
  background-color: transparent;
}

.collapsable-navbar-extended .exit-menu {
  height: 1.8rem;
  border: none;
  background-color: transparent;
  color: rgb(255, 255, 255) !important;
}
.collapsable-navbar-extended .exit-menu svg {
  height: 100%;
  width: 100%;
}
.collapsable-navbar-extended .modal-content  span {
  font-weight: 700 !important;
  font-size: 1.8rem;
  color: rgb(232, 230, 227) !important;
}
.collapsable-navbar-extended #mobile-menu-admin .nav-option a:hover {
  transition: 0s;
  background-color: transparent;
}

.collapsable-navbar-extended #mobile-menu-admin  svg {
  display: none;

}

#mobile-menu-admin  h3 {
  margin-left: 0.2rem;
}
