
.search-bar-container {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}
.new-product-btn {
  margin: 0;
  margin-bottom: 0.5rem;
}
.search-bar-container a {
  width: 20%;
  margin: 0px;
  align-self: center;
}
.admin-con .search-products {
  width: 85% !important;
}
.adminProdBtnRow {
  margin: 2% auto;
}
.edit-stock-modal .modal-content {
  margin-bottom: 7rem;
}

.button-container {
  display: flex;
  flex-direction: column; 
  width: 100%;
  align-items: center;
}

.action-btn {
  margin: 2px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px; 
  height: 30px; 
  border-radius: 25px; 
  border: 2px solid;
  background-color: transparent;
  text-decoration: none; 
  padding: 0 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

}

.icon-confirm{
  font-size: 18px;
  border: 2px solid !important;
  border-radius: 50%;
}


.accept-btn {
  background-color: white !important ; 
  border: 1px solid #28a745 !important;
  color: #28a745 !important;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  width: 40% !important;
}

.accept-btn .icon-confirm {
  color: #28a745 !important; 
  border-color: #28a745 !important;
}

.accept-btn:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.accept-btn:hover .icon-confirm{
  border-color: white !important;
  color: white !important;
}


.reject-btn {
  background-color: white !important;
  border: 1px solid #dc3545 !important;
  color: #dc3545 !important;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  width: 40% !important;

}

.reject-btn .icon-confirm {
  color: #c82333 !important; 
  border-color: #c82333 !important;
}

.reject-btn:hover {
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
  color: white !important
}

.reject-btn:hover .icon-confirm {
  color: white !important;
  border-color: white !important;
}


.button-container .icon {
  margin-right: 10px;
  margin-left: 2px;
  font-size: 24px;
  border: 2px solid;
  border-radius: 50%;
}


.accept-icon {
  color: #28a745; 
  border-color: #28a745;

}


.reject-icon {
  color: #dc3545;
  border-color: #dc3545;


}

.icon-button-confirm {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}

.icon-button .icon {
  margin-right: 10px;
  font-size: 20px;
}

/* .icon-confirm-icon {
  color: white;
  border: 2px solid;
  border-color: white ; 
  border-radius: 50%;
  width: 20px;
  height: 20px;

} */

/* !i */

.accept-icon{
  font-size: 24px;
}
.reject-icon{
  font-size: 24px;
}

.status-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 100%; 
}


.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.verify-flap-modal {
  max-width: 80%; 
}
.verify-btn {
  background-color: #eae7e7;
  border: 1px solid #ccc;
  color: #555;
  padding: 6px 12px !important; 
  text-align: center;
  font-size: 18px; /* Increase font size for icon */
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
  margin: 1px 1px;
}

.verify-btn:hover {
  background-color: #9e9e9e;
  color: black;
}

.verify-btn:active {
  background-color: #d7d7d7df;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
}

.close-icon {
  font-size: 1.5rem;
  color: #000;
}

.comments-form-control{
  height: 100px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f7f7f7; 
}