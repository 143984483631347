.new-image-cropper {
    width: 100%;
    .btn-outline-primary,
    .btn-outline-danger,
    .btn-outline-secondary {
        width: 100%;
        height: 100%;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
    }
    .ReactCrop__crop-selection {
        max-width: 100%;
        max-height: 100%;
    }

    padding: 1rem;

    .cropper-container {
        margin: 0 auto;
        width: fit-content;
        height: fit-content;
    }

}