.footer-bs {
  width: 100%;
  bottom: 40px;
  left: 0;
  padding: 2rem 1rem;
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  width: 100vw;
  box-shadow: inset 0px 0px 32px -6px var(--shadowNavColor);
}

.footerbrand {
  background-color: var(--primaryColor);
}

footer .col-12 {
  background-color: var(--primaryColor);
  padding: 0.1rem 0.2rem;
}

footer ul {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0;
}

footer li {
  list-style-type: none;
  line-height: normal;
  text-align: justify;
  margin: 0;
  font-size: 0.9rem;
}


footer p {
  text-align: left;
  color: var(--navFontColor);
  font-size: medium;
  font-weight: 600;
  font-size: 1rem;
}

footer h5 {
  text-align: left;
  color: var(--navFontColor);
  font-weight: 800;
  margin-bottom: 0;
}

footer img {
  width: 5rem;
  margin: 1rem 0;
}

footer .nav-link {
  display: -webkit-inline-box;
  align-items: center;
  color: var(--navFontColor) !important;
  height: auto !important;
  font-weight: 700;
}

footer .row {
  justify-content: center;
  align-content: center;
  width: 100%;
  margin: 0;
}

.whatsapp_float {
  padding: 0.15rem;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 42.4px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

@media screen and (min-width: 991px) {
  .footer-map-links {
    border-left: 1px solid var(--secondaryColor) !important;
  }
}
@media screen and (max-width: 991px) {
  footer p {
    text-align: center;
  }

  footer h5 {
    text-align: center;
  }

  footer li {
    text-align: center;
  }

  footer ul {
    border-bottom: var(--secondaryColor) 1px solid;
  }

 

  .footerbrand {
    border-bottom: none;
    border-right: none !important;
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .footerbrand-img {
    order: 1;
  }

  footer img {
    margin: auto;
    display: block;
    float: none;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}