.previous-drafts-modal {
    .draft {
        padding: 1rem;
        transition: 0.08s;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        border-bottom: 1px solid #00000034;

        &:first-of-type {
            border-top: 1px solid #00000057;
        }

        &:hover {
            transition: 0.08s;
            background-color: rgba(0, 0, 0, .04);

            button svg {
                transition: 0.08s;
                color: red;
            }
        }

        button {
            border: none;
            background-color: transparent;

            svg {
                color: transparent;
            }
        }
    }

    h3 {
        width: 100%;
        font-size: 1.1rem;
    }

    p {
        font-size: .9rem;
        margin: 0;
        width: 100%;
    }

    .draft-data {
        width: 90%;
    }

    .modal-body {
        padding: 0;
        min-height: 20vh;
        max-height: 45vh;
        overflow: auto;
    }

}