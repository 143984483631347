.button-outline-secondary {
    color: var(--secondaryColor);
    border-color: var(--secondaryColor);
    width: 100%;
    font-size: 0.85rem;
    border-radius: 1rem;
  }
  
  .button-outline-secondary:hover,
  .button-outline-secondary:focus {
    background-color: var(--secondaryColor);
    outline: none !important;
    box-shadow: none !important;
    color: var(--btnFontColor);
  }