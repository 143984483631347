#checkout-navigator {
    .desktop {
        position: fixed;
        justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.5rem 1rem;
        height: fit-content;
        background-color: white;
        box-shadow:0px -2px 4px 0px rgba(0,0,0,0.3);
            button {
                flex: 1;
                margin-bottom: 10px;
                scroll-behavior: auto;
            }
            @media screen and (max-width: 576px) {
                top: 80px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
            }
        }
        .bottom-section {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: white;
            box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
            padding: 0.5rem 1rem;
            height: fit-content;
                        button {
                                margin-bottom: 8px;

                            }
        }
    .checkout-stepper  {
        margin: 0;
        flex: 8 
    }

    button {
        width: fit-content;
        margin: 0;
        justify-content: center;
        border: none;
        min-height: 2.5rem;
        align-items: center;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 0.5rem;
        border-radius: 25px;

        span {
        }
        svg {
            margin:0  4px;
            font-size: 1.2rem;
        }
    }
}
