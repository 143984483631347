.product {
  border-radius: 0.25rem;
  text-align: center;
  border: 1.2px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
  h5 {
    font-size: 1.05rem;
    margin-bottom: 0.4rem;
  }

  h6 {
    font-size: 0.9rem;
  }

  .card {
    border-radius: 0.25rem;
    border: none;
  }

  img {
    height: 65%;
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    aspect-ratio: 1/1;
  }

  a {
    font-size: 75%;
    text-decoration: none;
    color: black;
    margin-bottom: 0.2rem;
    height: 25%;
  }

  .dropdown-item {
    color: var(--btnFontColor);
    font-weight: 600;
  }
}

.product-card-body {
  height: 15rem;
  margin: 0px;
  padding: 0.9rem;
}

.product-card-data {
  min-height: 100px;
  padding: 0.15rem;
}

.product-button.btn.btn-primary,
.product .select-option-dropdown {
  font-size: 0.95rem;
  width: 10rem;
  font-weight: 600;
}

@media (max-width: 1301px) and (min-width: 991px) {
  .product-card-data {
    min-height: 120px;
    padding: 0.25%;
  }

  .product-card-body {
    height: 16rem;
    margin: 0px;
  }
}