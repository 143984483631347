.dynamic-form {
textarea {
    min-width: fit-content;
    min-height: fit-content;
    max-height: 10vh;
  }
  .form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

}
