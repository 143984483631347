#new-product-variant-options  h2 {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.631);
}
#new-product-variant-options .row {
    margin-top: 0.2rem;
    min-height: 4.5rem;
    gap: 5px;
    padding: 0.3rem 0.6rem;
}

.option-name {
    font-size: 0.95rem;
}
.option-tabs {
    display: flex;
}
#new-product-variant-options .new-option-container  {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
#new-product-variant-options .new-option-container .pill-button {
 flex: 1;   
}
.variant-option-tab.nav-link.disabled {
    color: rgba(0, 0, 0, 0.425) !important;
}

 #new-product-variant-options .actions-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
 }
 #new-product-variant-options .actions-container.edit {
    align-items:  center;
 }
 .variant-option-tab .pill-button {
    flex:  0;
 }
 #new-product-variant-options .btn-outline-secondary {
    border-color: var(--secondaryColor);
    color: var(--secondaryColor);  
 }
 #new-product-variant-options .btn-outline-secondary:hover {
    background-color: var(--secondaryColor);
    color: white
 }
#new-product-variant-options .btn-outline-primary,
#new-product-variant-options .btn-outline-danger,
#new-product-variant-options .btn-outline-secondary {
    width: 100%;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
  }
#new-product-variant-options .new-option-container input  {
    border: none;
    flex: 5;
    text-align: start;
    font-weight: 700;
}
#new-product-variant-options .new-option-container input.empty:focus  {
    text-align: start;
}

#new-product-variant-options .new-option-container input.empty  {
    text-align: end;
}

#new-product-variant-options .new-option-container input::placeholder  {
    color: rgba(0, 0, 0, 0.71);
}
#new-product-variant-options .new-option-container input:focus::placeholder  {
    color: transparent
}
#new-product-variant-options .tab-content  {
    min-height: 8rem;
}
.pill-input-bordered {
    border-bottom:1px solid rgba(33, 37, 41, 0.346) !important;
}

/* TABLE */
.product-variants-table-container {
    justify-content: center;
    padding: 1rem;
    min-height: 10rem !important;
}
.table-footer-actions {
    height: 3rem;
    padding: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    background-color: rgba(63, 62, 62, 0.14) !important;

}
.table-footer-actions .btn-primary {
    margin: 0 !important;
    font-size: 0.92rem;
    font-weight: 500;
    border-radius: 7px;
    padding: 0.3rem 1rem !important;
}
.table-footer-actions .btn-primary:first-of-type {
    background-color: white !important;
    color: #206ad9 !important;
}
.table-footer-actions .btn-primary:nth-of-type(2) {
    background-color: #206ad9 !important;
    color: white !important;
}
.product-variants-table-container .table-responsive, .variants-table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.variants-table  {

    margin-bottom: 0 !important;
}
.variants-table thead {
    background-color: rgba(63, 62, 62, 0.14) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.variants-table th{
    border-width: 0 !important;
    padding: 0.4rem 0.5rem;
}
.variants-table tbody{
    border-top:none !important ;
}
.variants-table td{
    vertical-align: middle;
    padding: 0.15rem 0.6rem;
    font-size: 1rem;
    font-weight: 500;
    white-space-collapse: preserve;
}
.variants-table .dropdown-toggle.btn.btn-primary {
    background-color: transparent !important;
    width: 55%;
    border: 1px solid rgba(63, 62, 62, 0.523) !important;
}
.variants-table .dropdown-menu.show {
    background-color: #e7e7e7;
}
.variants-table .dropdown-toggle.btn.btn-primary::after {
    color: rgba(0, 0, 0, 0.778);
}
.variants-table  td:has(input[type='checkbox']), .variants-table .active-th  {
  border-right: none !important;
}
.variants-table  tr td:last-of-type, .variants-table tr th:last-of-type {
    border-left: none !important;
}
.variant-input{
    width: 4rem;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba(63, 62, 62, 0.388) !important;
}
.variant-input:disabled {
    border-bottom: none !important;
    background-color: transparent !important;
}
td:has(.variant-input.changed), .variant-input.changed,
td:has(.form-check-input.changed),td:has(.form-check-input.changed) + td{
    background-color: #eec192;
}
.variant-input.changed {
    border-bottom: none !important;
}

.variant-input[readonly], .variant-input:has(input[type="checkbox"]){
    border: none !important;
}
/* END TABLE */



.variant-option-tab {
    width: 100%;
    text-align: center;
    display: block;
    color: black !important;
}
.variant-option-tab.active {
    background-color: var(--primaryColor) !important;
    color: white !important;
}
.variant-option-tab.active svg {
    color: white !important;
}

.option-value-container {
    font-weight: normal;
    margin-right: 0.2rem;
    padding-top: 0.15rem;
    height: 50%;
}




#option-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    justify-content: flex-start;
    margin-bottom: 0.25rem;
}



#new-product-variant-options-body .list-group-item {
    border-right: none;
    border-left: none;
    border-radius: 0;
}