.products {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 2rem 4rem 2rem;
}

.products .card-title .h5 {
  font-size: 1rem !important;
}


