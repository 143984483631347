#admin-categories-container {
    h1 {
        margin-top: .35rem;
        margin-left: .2rem;
        font-weight: 400;
    }
    .section-title {
        font-weight: 400;
    
        svg {
            font-size: 2.5rem;
        }
    }
}

