.address-book {
   border: 1px solid rgb(229, 229, 229); 
  background-color: white;
  margin-top: 0.5rem;
  max-width: 65rem !important;
}
.address-book .error-message {
  font-size: 0.9rem;
  font-weight: 500;
  color: #dc3545;
}
.address-book .address-message {
  padding-right: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: black;
}

.address-text-buttons {
  color: blue !important;
  text-decoration: blue underline !important;
  font-size: 0.8rem;
}
.address-book-order-details {
  padding-top: 0.5rem;
}

.address-text-buttons:hover {
  cursor: pointer;
}

.address-book-header {
  padding-left: 0.5rem;
}

.address-book-body {
  max-height: 50rem;
  padding: 0 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.alert-alert-primary {
  margin: 0.5rem 0;
}
.address-book-controls {
  height: 2.5rem;
}



.address-book h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.address-book .btn.btn-primary {
  margin: 0 !important;
}

.address-book textarea.form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.9rem;
  border-radius: 0.18rem;
  border: 1px solid #ced4da !important;
}

.address-book .form-floating input:focus + label,
.address-book .form-floating select:focus + label,
.address-book .form-floating textarea:focus + label {
  color: var(--secondaryColor);
}
.address-book .form-floating input:focus,
.address-book .form-floating select:focus,
.address-book .form-floating textarea:focus {
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor) !important;
  box-shadow: none;
}
.address-book #shipping-date-input {
  border: 1px solid #ced4da !important;
}

[type="date"] {
  background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/32x32/calendar_2.png) 97% 50% no-repeat;
  
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.address-book textarea.form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.9rem;
}
.address-book textarea + label {
  padding: 1rem 1.5rem;
}
.address-book .btn.btn-outline-secondary {
  color: var(--btnFontColor);
  border-color: var(--secondaryColor);
  background-color: var(--secondaryColor);
  font-weight: 700;
  width: 10rem;
  font-size: 0.85rem;
  border-radius: 1rem;
}

.address-book .btn.btn-outline-secondary:hover,
.address-book .btn.btn-outline-secondary:focus {
  transition: .2s;
  outline: none !important;
  box-shadow: none !important;
  filter: brightness(90%);

}
.address-book .btn.btn-outline-danger {
  color: var(--btnFontColor);
  border-color: #dc3545;
  background-color: #dc3545;
  font-weight: 700;
  width: 10rem;
  font-size: 0.85rem;
  border-radius: 1rem;
}

.address-book .btn.btn-outline-secondary:hover,
.address-book .btn.btn-outline-secondary:focus {
  transition: .2s;
  outline: none !important;
  box-shadow: none !important;
  filter: brightness(90%);

}


@media screen and (min-width: 992px) and (max-width: 1050px){
  .address-book-body {
    width: 700px;
    margin: auto !important;
    padding: 0 !important;
    justify-content: start !important;
  }
  .address-card {
    width: 22rem !important;
  }
}
@media screen and (min-width: 721px) and (max-width: 992px){
  .address-book-body {
    width: 650px;
    margin: auto !important;
    padding: 0 !important;
    justify-content: start !important;
  }
  .address-card {
    width: 22rem !important;
  }
}
@media screen and (max-width: 721px){
  .address-book-body {
    justify-content: center !important;
    margin: auto !important;
  }
}
