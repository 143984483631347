.dynamic-form-modal {
    position: relative;
    top: 25%;
    .modal-content {
        padding: 1rem;

    }
    .modal-header {
        border-bottom: none !important;
    }
    .modal-content {
        padding: 1rem;
    }
    button {
        margin: 0 !important;
    }
    .form-control {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
    }
}