.admin-list-container {
  background-color: white;
}
#admin-products-container {
  background-color: white;
  padding-right: .15rem !important;
  @media screen and (max-width: 992px) {
    padding: .7rem !important;
  }
  @media screen and (max-width: 768px) {
    padding: .3rem .1rem !important;
  }
  h1
  
  {
    margin-top: .2rem; 
    margin-left: .2rem; 
    font-weight: 400;
    svg {
    font-size: 2.5rem;
  }}
}
.search-bar-container {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}
.new-product-btn {
  margin: 0;
  margin-bottom: 0.5rem;
}
.search-bar-container a {
  width: 20%;
  margin: 0px;
  align-self: center;
}
.admin-con .search-products {
  width: 85% !important;
}
.adminProdBtnRow {
  margin: 2% auto;
}
.edit-stock-modal .modal-content {
  margin-bottom: 7rem;
}
