.product-image-preview {
    background-position: center;
}
.variant-input-row {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.image-cropper-trigger + p {
    font-size: 0.85rem;
}
#new-product-variant-settings {
    figure {
        height: calc(15rem + 40px);
        width: 100%;
    }
    figure img {
        height: 100%;
        width: 100%;
    }
    .card-header span {
        font-size: 0.8rem;
        display: inline-block;
        margin-left: .75rem;
        color: rgba(189, 3, 3, 0.759);
    }
}
