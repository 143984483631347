/* General Status Span Styles */
.status-span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: normal;
  min-width: 100px;
  text-align: center;
  margin: 1.5px;
}

/* Center Span within Table Cell */
td > .status-span {
  display: block;
  margin: 0 auto;
}

/* Status-specific styles */
.status-span.status-cancelled {
  background-color: #F8D7DA;
  color: #721C24;
  border: 1px solid #F5C6CB;
}

.status-span.status-pending-payment {
  background-color: #FFE0B2;
  color: #856404;
  border: 1px solid #FFE599;
}

.status-span.status-awaiting-confirmation {
  background-color: #FFF3CD;
  color: #856404;
  border: 1px solid #FFEEBA;
}

.status-span.status-completed {
  background-color: #D4EDDA;
  color: #155724;
  border: 1px solid #C3E6CB;
}
