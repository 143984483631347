.dynamic-list-table {
    table-layout: fixed;
    position: relative;
    .actions-col, .checkbox-col {
            width: 1.8rem !important;
            vertical-align: middle;
            overflow: visible;
    }
    &:has(.flap-status-cell) {
        .actions-col{
            width: auto !important;
        }
    }
    .checkbox-col .checkbox-container {
            .checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
    }
    .actions-col .actions-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                position: relative;
    }
    thead {
        font-size: 0.875rem;
        vertical-align: middle;
        text-align: center;
        th {
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
            /* word-break: break-all; */
        }
    }
    tbody {
        font-size: 0.85rem;
        border-top: none !important;
        td {
            background-color:  white;
            word-break:normal;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
  
    .details-menu {
        position: absolute;
        right: auto;
        left: -150px;  /* Adjust this value based on your menu width */
        top: 100%;
        margin-top: 0.5rem;
        background-color: white !important;
        border-width: 2px;
        font-size: .8rem;
        padding: 0;
        min-width: 150px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 1000;
        transform: none !important;
        .action {
            a {
                transition: 0.3s;
                font-weight: 400;
                text-align: left;
                padding: 0.5rem 1rem;
                white-space: nowrap;
            }
            &:hover {
                transition: 0.3s;
                background-color: #eff2f5a2;
            }
        }
    }
}

.dropdown-dynamic-table {
    position: relative;
    .dropdown-toggle {
        cursor: pointer;
        transition: 0.1s;
        font-size: 1rem;
        transform: rotate(180deg);
        background: none;
        border: none;
        padding: 0;
        margin: 0;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        transform: translateX(-100%);
        background-color: white;
        border: 2px solid #dee2e6;
        border-radius: 0.25rem;
        padding: 0;
        min-width: 150px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 1000;

        .dropdown-item {
            padding: 0.5rem 1rem;
            white-space: nowrap;
            transition: 0.3s;
            text-align: left;

            &:hover {
                background-color: #eff2f5a2;
            }
        }
    }

    .dropdown-toggle.show {
        transition: 0.1s;
        color: rgba(0, 0, 0, 0.525);
        transform: rotate(0);
    }

    &::before {
        display: none;
    }
}