.image-cropper-container {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: relative;
  height: calc(15rem + 40px);
}

.image-cropper-container img {
  height: 100%;
  width: 100%;
}
.image-cropper-container .btn-outline-primary,
.image-cropper-container .btn-outline-danger,
.image-cropper-container .btn-outline-secondary {
    width: 100%;
    height: 100%;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
  }
.image-cropper-container figure {
  height: calc(15rem + 40px);
  width: 100%;
}

.image-cropper-container figcaption {
  padding: 0.25rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  height: 45px;
  gap: 2px;
  background-color: rgba(0, 0, 0, 0.761) !important;
}

.image-cropper-trigger {
  width: 100%;
  height: 100%;
  background-color: #e1eef4;
  min-height: 15rem;
  position: relative;
  border: none;
}

.image-cropper-trigger div {
  text-align: center;
  color: rgba(63, 62, 62, 0.9);
  font-weight: 500;
  font-size: 1.2rem;
}

.image-cropper-container .controls {
  width: 100%;
  height: 40px;
  gap: 10px;
  padding: 0.2rem;
  background-color: rgba(63, 62, 62) !important;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.controls input {
  flex: 5;
}

.controls button {
  flex: 1;
  border-radius: 8%;
  border: none;
  padding: 0;
  color: white;
  padding: 0.1rem 0;
}

.controls button.cancel {
  background-color: rgb(206, 16, 16);
}

.controls button.confirm {
  flex: 1;
  background-color: rgb(15, 178, 15);
}

.zoom-range {
  height: 2px;
  width: 100%;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}