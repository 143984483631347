.cart-item {
    margin: 0.5rem 0;
    padding: 0.5rem;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.069);
    &:last-of-type {
     border-bottom: none;
    }
    .item-name {
     font-weight: 500;
    }
    a.col-2 {
     border-radius: 8px;
     max-height: 6rem;
  
     img {
      border-radius: 8px;
  
      &.empty {
       border-radius: 8px;
       border: 1px solid rgba(0, 0, 0, 0.198);
      }
     }
    }
    .item-data {
     div:first-of-type {
      font-size: 0.92rem;
     }
     font-size: 0.85rem;
     color: rgba(0, 0, 0, 0.813);
     font-weight: 300;
  
     .item-option:nth-of-type(odd)::after {
      content: " - ";
     }
     .item-option:last-of-type::after {
      content: "";
     }
    }
    .cart-item-preview {
      position: relative;
      .badge {
        background-color: rgb(134, 134, 134) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5%;
        top: -11%;
        width: 23%;
        max-width: 35px;
        max-height: 35px;
        height: 23%;
        font-size: 85%;
        text-align: center;
        border-radius: 50px;
      }
    }
    .quantity-selector {
      span {
          display: inline-block;
          font-weight: 200;
          margin: 0 0.25rem;
      }
      button {
          aspect-ratio: 1/1;
          margin: 0;
          padding: 0;
          text-align: center;
          font-weight: 300;
          background-color: #0000000c;
          border-radius: 50px;
          width: 50%;
          border: none;
      }
    }
    .delete-button {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      svg {
        font-weight: 500;
        font-size: 1.8rem;
        color: rgb(206, 5, 5);
      }
    }
   }