.confirm-modal {
    position: fixed;
    transform: translate(-50%, -50%);
    margin-bottom: 25%;

    .modal-body:has(.grid) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
            width: 100%;
            visibility: hidden;
        }
    }


    .modal-content {
        border-radius: 9px;
        border: none;
    }

    .modal-header {
        margin: 0 .75rem;
        font-size: .95rem;

        .modal-title {
            font-size: 1.2rem;

        }
    }

    .modal-footer {
        border: none;

        button {
            margin: 0 .15rem;
        }

        .confirm {
            background-color: #c0c0c0 !important;
            color: black !important;
        }

        .cancel {
            background-color: #e2e2e2 !important;
            color: black !important;
        }
    }

    .modal-body {
        &.loading {
            min-width: none;
        }
    }
}

.modal.show+.modal-backdrop {
    z-index: 2000;
}

.modal.show:has(.confirm-modal) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 2000;

}

/* Custom backdrop for the confirmation modal */
.custom-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(66, 210, 95, 0.5) !important;
    /* Semi-transparent black */
    z-index: 1040;
    /* Ensure it's above the EventEditor modal */
}

/* Ensure the confirmation modal is above the backdrop */
.confirmation-modal {
    z-index: 1050;
    /* Ensure it's above the custom backdrop */
}